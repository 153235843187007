@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Poppins, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 14px !important
  }
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(165, 164, 164);
  transform: scale(1.1);
}
.MuiFormControl-root .css-1nrlq1o-MuiFormControl-root
{
    background-color: green;
  }

  .MuiButtonBase-root .MuiButton-root .MuiButton-contained .MuiButton-containedSuccess .MuiButton-sizeSmall .MuiButton-containedSizeSmall .Mui-disabled .MuiButton-root .MuiButton-contained .MuiButton-containedSuccess .MuiButton-sizeSmall .MuiButton-containedSizeSmall .css-vz0yde-MuiButtonBase-root-MuiButton-root{
    background-color: green;
  }


@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color:  rgb(238, 243, 238) rgb(116, 117, 116);
    scrollbar-width: thin;
  }
}

.overflowYAuto{
  overflow-y: auto;
}
#swagger-editor {
  font-size: 1.3em;
}

.container {
  height: 100%;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

#editor-wrapper {
  height: 100%;
  border: 1em solid #000;
  border: none;
}

.Pane2 {
  overflow-y: scroll;
}

.swagger-editor .SplitPane {
  left: 70px !important; 
  right: 10px !important; 
  height: calc(100% - 46px) !important
}
.MuiFormLabel-asterisk {
  color: red;
}